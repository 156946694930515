<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-form-item label="输入查询">
            <el-input v-model="queryModule.projectName" placeholder="模型名称" />
          </el-form-item>
        </el-col>
        
        <el-col :span="8">
          <el-form-item label="状态">
            <el-input v-model="queryModule.state" placeholder="全部" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主题">
            <el-input v-model="queryModule.theme" placeholder="全部" />
          </el-form-item>
        </el-col>
      </QueryModule>
      
      <el-table class="m-t-20"  :data="tableData" fit stripe>
        <el-table-column label="序号" type="index" />
        <el-table-column label="名称" prop="t1" />
        <el-table-column label="描述" prop="t2" />
        <el-table-column label="所属主题" prop="t3" />
        <el-table-column label="创建时间" prop="t4" />
         <el-table-column label="发布状态" prop="t5" />
         <el-table-column label="操作" width="260px" >
             <el-button type="primary"  >查看</el-button>
             <el-button type="primary"  @click="toResult()">编辑</el-button>
             <el-button type="primary"  @click="del()">删除</el-button>
         </el-table-column>
      </el-table>
      
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
import QueryModule from '/src/components/QueryModule' //查询
import { mapGetters } from 'vuex'

export default {
  name: 'SystemProject',
  //注册组件
  components: {
    BreadCrumb,
    QueryModule,
  },
  data() {
    return {
      loading: true,
      // table数据
      tableData: [
        {
        t1: '基础信息质量检测模型',
        t2: '检测6项基础信息质量',
        t3: '人口信息',
        t4:'2022-06-21',
        t5:'未发布'
      },
      {
        t1: '基础信息质量检测模型',
        t2: '基础信息质量检测模型',
        t3: '人口信息',
        t4:'2022-06-21',
        t5:'未发布'
      },
  ], //当前页的 数据
      //翻页
      queryModule: {
        //查询
        createBy: '',
        state:'',
        theme:'',
        projectName: '',
        totalElements: 0,
        currentPage: 1,
      },
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
   
  },
  computed: {
    ...mapGetters(['pageSize', 'user']),
  },
  methods: {
    toResult(){
      this.$router.push({
        name: 'dataModelAdd'
      })
    },
    del(){
      var _this = this
      this.$confirm('此操作将永久删除该数据，是否继续操作?', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
          _this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //清空对象
    reset() {
      // this.queryModule.projectName = ''
      // this.queryModule.currentPage = 1
      // this.projectList()
    },
    //查询
    queryRes() {
      // this.projectList()
    },
  },
}
</script>

<style></style>
